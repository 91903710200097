import Swiper from 'swiper'
import { A11y, FreeMode, Navigation } from 'swiper/modules'

export const selector = '.timeline-swiper'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Timeline Swiper')

    const timelineSwiper = new Swiper(el, {
        modules: [A11y, FreeMode, Navigation],
        spaceBetween: 0,
        slidesPerView: 'auto',
        lazyPreloadPrevNext: 1,
        freeMode: {
            enabled: true,
            sticky: false
        },
        breakpoints: {
            992: {
                freeMode: false,
                speed: 600
            }
        },
        navigation: {
            nextEl: '.timeline-swiper-button-next',
            prevEl: '.timeline-swiper-button-prev'
        }
    })

    // Log instance
    console.log(timelineSwiper)

    console.groupEnd()

    return timelineSwiper
}
