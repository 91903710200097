import Swiper from 'swiper'
import { A11y, EffectFade, Navigation, Pagination } from 'swiper/modules'

export const selector = '.quote-swiper'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Quote Swiper')

    const quoteSwiper = new Swiper(el, {
        modules: [A11y, EffectFade, Navigation, Pagination],
        spaceBetween: 0,
        speed: 300,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        slidesPerView: '1',
        pagination: {
            el: '.quote-swiper-pagination',
            type: 'fraction',
            clickable: false
        },
        navigation: {
            nextEl: '.quote-swiper-button-next',
            prevEl: '.quote-swiper-button-prev'
        }
    })

    // Log instance
    console.log(quoteSwiper)

    console.groupEnd()

    return quoteSwiper
}
