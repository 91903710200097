import Shuffle from 'shufflejs'

export const selector = '.swatch-grid-dynamic'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Dynamic Swatch Grid')

    let animateInterval

    let animateRounds = 0

    const shuffle = new Shuffle(
        el.querySelector(`${selector}-row`), {
            itemSelector: `${selector}-item`,
            easing: 'none',
            speed: 0
        }
    )

    const animate = () => {
        const complete = (animateRounds === 5)

        animateRounds++

        shuffle.sort({
            randomize: !complete
        })

        if (complete) {
            clearInterval(animateInterval)
            shuffle.destroy()
            io.unobserve(el)
        }
    }

    const ioCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting
                && shuffle)
                animateInterval = setInterval(animate, 500)
        })
    }

    const ioOptions = {
        root: null,
        rootMargin: '10px',
        threshold: 0
    }

    const io = new window.IntersectionObserver(ioCallback, ioOptions)
    io.observe(el)

    // Log instance
    console.log(shuffle)

    console.groupEnd()

    return shuffle
}
