// External library
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const selector = '[data-animate="image-text-fixed"]'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Image + Text - Fixed Scroll')

    const sections = gsap.utils.toArray(
        el.querySelectorAll('.image-text-item')
    )

    const sectionsWrapper = el.querySelector('.image-text-wrapper')

    // If there's less than two sections
    if (!sections || sections.length < 2)
        return

    // Save styles to be restored in mobile
    ScrollTrigger.saveStyles([
        '.image-text-item',
        '.image-text-wrapper',
        '.image-text-content',
        '.image-text-image'
    ])

    const matchMedia = gsap.matchMedia()

    matchMedia.add('(min-width: 1240px)', () => {
        const maxHeight = Math.max(...sections.map(section =>
            section.offsetHeight))

        el.style = `--scrolling-asset-height: ${maxHeight}px`

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: 'center center',
                end: `bottom -${sections.length * 50}%`,
                scrub: true,
                pin: sectionsWrapper,
                pinnedContainer: sectionsWrapper,
                smooth: true
            }
        })

        sections.forEach((item, i) => {
            const mediaEl = item.querySelector('.image-text-image')
            const textEl = item.querySelector('.image-text-content')

            gsap.set(item, {
                position: 'absolute',
                top: 0
            })

            timeline.from(textEl, {
                autoAlpha: i
                    ? 0
                    : 1,
                y: i
                    ? '100%'
                    : 0
            }, i)

            if (i)
                timeline.from(mediaEl, {
                    autoAlpha: 0
                }, i)

            if (i !== sections.length - 1)
                timeline
                    .to(textEl, {
                        y: '-100%',
                        autoAlpha: 1,
                        ease: 'expo'
                    })
                    .to(mediaEl, {
                        autoAlpha: 1,
                        ease: 'circ'
                    }, '<25%')
        })
    })

    // Log instance
    console.log(sections)

    console.groupEnd()

    return el
}
