export const selector = '.reclamation-form'

export default (
    el = null
) => {
    const form = el
        && el.querySelector('form')

    if (!form)
        return el

    const formSubmissionMessage = form
        .querySelector('.reclamation-form-submission-message')

    const formSubmissionMessageError = formSubmissionMessage
        && formSubmissionMessage.querySelector('.error')

    const formSubmitBtn = form
        .querySelector('button[type=submit]')

    console.groupCollapsed('Reclamation Form')

    form.noValidate = true

    const onFormSubmissionMessageFocus = (e) => {
        if (formSubmissionMessage.getAttribute('role') === 'alert')
            return

        const inputs = Array.from(form.querySelectorAll(
            'input:not([type=hidden]), select, textarea'))

        inputs
            .forEach(input => (input.value = ''))

        form.classList.remove('was-validated')

        console.log('Focus', e)
    }

    const onFormSubmissionMessageBlur = (e) => {
        const isSubmissionError = formSubmissionMessage
            .getAttribute('role') === 'alert'

        if (isSubmissionError)
            formSubmitBtn.disabled = false

        if (formSubmissionMessage === document.activeElement
                || formSubmissionMessage.contains(document.activeElement)
                || isSubmissionError)
            return

        formSubmissionMessage.removeAttribute('role')

        if (formSubmissionMessageError)
            formSubmissionMessageError.innerHTML = ''

        if (formSubmitBtn)
            formSubmitBtn.disabled = false

        console.log('Blur', e, document.activeElement)
    }

    const onFormSubmit = async (e) => {
        e.preventDefault()

        formSubmissionMessage.removeAttribute('role')

        if (!form.classList.contains('was-validated'))
            form.classList.add('was-validated')

        if (!form.checkValidity()) {
            const invalid = form.querySelector(':invalid')

            if (invalid)
                invalid.focus()

            return
        }

        if (formSubmitBtn) {
            formSubmitBtn.disabled = true
            formSubmitBtn.innerText = 'Creating UPS Label...'
        }

        const request = await window
            .fetch(form.getAttribute('action'), {
                method: form.method || 'GET',
                body: new window.URLSearchParams(new window.FormData(form))
                    .toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(res => res.blob())
            .catch(err => console.warn(err))

        if (formSubmitBtn)
            formSubmitBtn.innerText = 'Create UPS Label'

        if (!request
                || (request || {}).type !== 'image/gif') {
            if (formSubmissionMessageError)
                formSubmissionMessageError
                    .innerText = await request.text()

            formSubmissionMessage.setAttribute('role', 'alert')

            formSubmissionMessage.focus()

            return
        }

        formSubmissionMessage.setAttribute('role', 'status')

        const url = window.URL.createObjectURL(request)

        const anchor = document.createElement('a')

        anchor.className = 'visually-hidden'

        anchor.href = url

        anchor.download = 'Designtex-Reclamation-UPS-Label.gif'

        formSubmissionMessage.appendChild(anchor)

        anchor.click()

        formSubmissionMessage.removeChild(anchor)

        formSubmissionMessage.focus()
    }

    form.addEventListener('submit', onFormSubmit)

    if (formSubmissionMessage) {
        formSubmissionMessage
            .addEventListener('focus', onFormSubmissionMessageFocus)

        formSubmissionMessage
            .addEventListener('blur', onFormSubmissionMessageBlur)
    }

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                form.removeEventListener('submit', onFormSubmit)

                if (formSubmissionMessage) {
                    formSubmissionMessage
                        .removeEventListener('focus', onFormSubmissionMessageFocus)

                    formSubmissionMessage
                        .removeEventListener('blur', onFormSubmissionMessageBlur)
                }
            })

    console.log(form)

    console.groupEnd()

    return el
}
