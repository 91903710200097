import { tabbable } from 'tabbable'

export const selector = '.hotspots'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Hotspots')

    const hotspotTooltipToggleBtns = Array.from(
        el.querySelectorAll('[data-bs-toggle=tooltip]'))

    const offcanvasEls = Array.from(document.querySelectorAll([
        '.offcanvas',
        '.offcanvas-sm',
        '.offcanvas-md',
        '.offcanvas-lg',
        '.offcanvas-xl',
        '.offcanvas-xxl'
    ].join(',')))

    const offcanvasParent = offcanvasEls
        .filter(_el => _el.contains(el))[0]

    const getOrCreateBtnInstance = (btn) => window.bootstrap.Tooltip
        .getOrCreateInstance(btn, {
            trigger: 'manual',
            sanitize: false,
            html: true,
            container: offcanvasParent || document.body
        })

    const isTargetToggleBtnOrTooltip = (target, btn) => {
        const instance = getOrCreateBtnInstance(btn)

        return instance
            && target
            && btn
            && (target === btn
                || target === btn.parentElement
                || (instance.tip
                    && (target === instance.tip
                        || instance.tip.contains(target))))
    }

    const onHide = (e) => {
        e.target
            .removeAttribute('data-trigger-event')

        e.target.parentElement.parentElement
            .removeAttribute('aria-owns')
    }

    const onShow = (e) => {
        const btn = e.target

        const instance = btn
            && getOrCreateBtnInstance(btn)

        if (!instance)
            return

        const hotspots = Array.from(
            document.querySelectorAll('.hotspots-item-tooltip.tooltip'))

        hotspots
            .filter(tooltip => tooltip
                && tooltip.id
                && tooltip !== instance.tip)
            .forEach((tooltip) => {
                const toggle = document
                    .querySelector(`[data-bs-toggle=tooltip][aria-describedby=${
                        tooltip.id}]`)

                const _instance = getOrCreateBtnInstance(toggle)

                if (_instance)
                    _instance.hide()
            })
    }

    const onShown = (e) => {
        const btn = e.target

        const instance = btn
            && getOrCreateBtnInstance(btn)

        if (!instance)
            return

        if (instance.tip
                && btn.parentElement.parentElement.getAttribute('aria-owns')
                    !== instance.tip.id)
            btn.parentElement.parentElement
                .setAttribute('aria-owns', instance.tip.id)
    }

    const onToggleBtnMouseEnter = (e) => {
        const instance = e.target
            && getOrCreateBtnInstance(e.target)

        if (!instance
                || e.target.getAttribute('aria-describedby')
                || e.target.getAttribute('data-trigger-event'))
            return

        e.target.setAttribute('data-trigger-event', 'mouseenter')

        instance.show()
    }

    const onToggleBtnClick = (e) => {
        const instance = e.target
            && getOrCreateBtnInstance(e.target)

        if (!instance)
            return

        const triggerEvent = e.target.getAttribute('data-trigger-event')

        if (!triggerEvent)
            e.target.setAttribute('data-trigger-event', 'click')

        if (triggerEvent === 'click'
                || !triggerEvent)
            return !triggerEvent
                ? instance.show()
                : instance.hide()

        const isShowing = Boolean(e.target.getAttribute('aria-describedby'))

        if (isShowing)
            e.target.setAttribute('data-trigger-event', 'click')
    }

    const onDocumentClick = (e) => hotspotTooltipToggleBtns
        .filter(btn => btn.getAttribute('aria-describedby')
            && btn.getAttribute('data-trigger-event') === 'click')
        .forEach((btn) => {
            if (isTargetToggleBtnOrTooltip(e.target, btn))
                return

            const instance = getOrCreateBtnInstance(btn)

            instance.hide()
        })

    const onDocumentMouseMove = (e) => hotspotTooltipToggleBtns
        .filter(btn => btn.getAttribute('aria-describedby')
            && btn.getAttribute('data-trigger-event') === 'mouseenter')
        .forEach((btn) => {
            if (isTargetToggleBtnOrTooltip(e.target, btn))
                return

            const instance = getOrCreateBtnInstance(btn)

            instance.hide()
        })

    const onDocumentKeyDown = (e) => {
        const key = (e.key || e.keyIdentifier || '')

        if (!key.match(/(escape|tab)/i))
            return

        hotspotTooltipToggleBtns
            .filter(btn => btn.getAttribute('aria-describedby'))
            .forEach((btn) => {
                const instance = getOrCreateBtnInstance(btn)

                if (key.match(/escape/i))
                    return instance.hide()

                const tabbableTipEls = tabbable(instance.tip)

                if (!tabbableTipEls)
                    return

                const tabbableDocEls = tabbable(document.body)
                    .filter(_el => tabbableTipEls.indexOf(_el) < 0)

                const toggleBtnIndex = tabbableDocEls.indexOf(btn)

                const tabbableDocElsStart = tabbableDocEls
                    .slice(0, toggleBtnIndex + 1) || []

                const tabbableDocElsEnd = tabbableDocEls
                    .slice(toggleBtnIndex + 1) || []

                const tabbableEls = tabbableDocElsStart
                    .concat(tabbableTipEls)
                    .concat(tabbableDocElsEnd)

                const activeIndex = tabbableEls
                    .indexOf(document.activeElement)

                let focusEl = tabbableEls[activeIndex + (e.shitKey ? -1 : 1)]

                if (!focusEl)
                    focusEl = e.shiftKey
                        ? tabbableEls[tabbableEls.length - 1]
                        : tabbableEls[0]

                if (!focusEl)
                    return

                e.preventDefault()
                e.stopPropagation()

                focusEl.focus()
            })
    }

    const onResize = (e) => {
        const bounds = el.getBoundingClientRect()

        hotspotTooltipToggleBtns
            .forEach((btn) => {
                const btnBounds = btn.getBoundingClientRect()

                const isOutOfBounds = btnBounds.left > bounds.right
                    || btnBounds.left < bounds.left
                    || btnBounds.top > bounds.bottom
                    || btnBounds.top < bounds.top

                const instance = getOrCreateBtnInstance(btn)

                if (isOutOfBounds
                        && btn.getAttribute('aria-describedby'))
                    instance.hide()

                if (isOutOfBounds
                        && btn.style.visibility !== 'hidden')
                    btn.style.visibility = 'hidden'

                if (!isOutOfBounds
                        && btn.style.visibility === 'hidden')
                    btn.style.visibility = ''
            })
    }

    hotspotTooltipToggleBtns
        .forEach((btn) => {
            getOrCreateBtnInstance(btn)

            btn.addEventListener('mouseenter', onToggleBtnMouseEnter)

            btn.addEventListener('click', onToggleBtnClick)

            btn.addEventListener('hide.bs.tooltip', onHide)

            btn.addEventListener('show.bs.tooltip', onShow)

            btn.addEventListener('shown.bs.tooltip', onShown)
        })

    document.addEventListener('click', onDocumentClick)

    document.addEventListener('keydown', onDocumentKeyDown)

    document.addEventListener('mousemove', onDocumentMouseMove)

    window.addEventListener('resize', onResize)

    onResize()

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                hotspotTooltipToggleBtns
                    .forEach((btn) => {
                        btn.removeEventListener('mouseenter',
                            onToggleBtnMouseEnter)

                        btn.removeEventListener('click',
                            onToggleBtnClick)

                        btn.removeEventListener('hide.bs.tooltip',
                            onHide)

                        btn.removeEventListener('show.bs.tooltip',
                            onShow)

                        btn.removeEventListener('shown.bs.tooltip',
                            onShown)
                    })

                document.removeEventListener('click', onDocumentClick)

                document.removeEventListener('keydown', onDocumentKeyDown)

                document.removeEventListener('mousemove', onDocumentMouseMove)

                window.removeEventListener('resize', onResize)
            })

    console.log(el)

    console.groupEnd()

    return el
}
