export const selector = '.product-categories'

export default (
    el = null
) => {
    if (!el
            || document.body.classList.contains('touch'))
        return

    console.groupCollapsed('Product Categories')

    const items = el.querySelectorAll(`${selector}-content-item a`)

    const hoverArea = el.querySelector('[data-hover-target]')

    const preload = (src) => {
        const img = new window.Image()
        img.src = src
        img.alt = ''
        img.setAttribute('data-object-fit', 'cover')
        img.setAttribute('data-object-position', '50% 50%')
        return img
    }

    if (!hoverArea
            || items.length < 2)
        return

    items.forEach((item) => {
        const imgHover = item.dataset.hoverSrc

        const getHoverAreaImg = () =>
            hoverArea.querySelector(`[src="${imgHover}"]`)

        if (!imgHover)
            return

        const preloadImg = !getHoverAreaImg()
            && preload(imgHover)

        const onImgLoad = (e) => {
            e.target.setAttribute('width', e.target.naturalWidth)
            e.target.setAttribute('height', e.target.naturalHeight)
        }

        if (preloadImg) {
            preloadImg.addEventListener('load', onImgLoad)
            hoverArea.appendChild(preloadImg)
        }

        const onMouseEnter = (e) => {
            const current = hoverArea.getAttribute('data-hover-target')

            const imgActive = getHoverAreaImg()

            if (imgHover === current
                    || !imgActive)
                return

            hoverArea
                .setAttribute('data-hover-target', imgHover)

            const imgPrev = hoverArea.querySelector(`[src="${current}"]`)

            window.requestAnimationFrame(() => {
                imgActive.className = 'active'

                if (imgPrev)
                    imgPrev.classList.remove('active')
            })
        }

        item.addEventListener('mouseenter', onMouseEnter)

        if (import.meta.hot)
            import.meta.hot
                .on('vite:beforeUpdate', () => {
                    if (preloadImg)
                        preloadImg.removeEventListener('load', onImgLoad)

                    item.removeEventListener('mouseenter', onMouseEnter)
                })
    })

    // Log instance
    console.log(el)

    console.groupEnd()

    return el
}
