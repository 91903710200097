export const selector = '.hero-video-modal'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Hero Video Modal')

    const iframe = el.querySelector('iframe')
    const videoPath = iframe?.src

    if (videoPath) {
        el.addEventListener('show.bs.modal', () => {
            iframe.setAttribute('src', videoPath)
        })

        el.addEventListener('hide.bs.modal', () => {
            iframe.setAttribute('src', '')
        })
    }

    // Log instance
    console.log(el)

    console.groupEnd()

    return el
}
