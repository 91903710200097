// External library
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const selector = '[data-animate="asset-list"]'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Asset + List')

    const lists = gsap.utils.toArray(
        el.querySelectorAll('.asset-list-column')
    )

    // If there's no list or items
    if (!lists)
        return

    const matchMedia = gsap.matchMedia()

    matchMedia.add('(min-width: 992px)', () => {
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: 'top 70%'
            }
        })

        lists.forEach((list, index) => {
            const items = list.querySelectorAll('li')

            if (!items)
                return

            gsap.set(list, {
                opacity: 0,
                y: '-50px'
            })

            gsap.set(items, {
                opacity: 0,
                y: '-20%'
            })

            timeline.to([list, items], {
                opacity: 1,
                duration: 1.3,
                y: 0,
                stagger: 0.2,
                ease: 'expo'
            }, (index / 2))
        })
    })

    // Log instance
    console.log(lists)

    console.groupEnd()

    return el
}
