export const getLocationSearchParams = (str = window.location.search) =>
    decodeURI(str || '')
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value], idx) => {
            const arrayMatch = key
                .match(/^(.+)\[([^\]]*)\]$/) || []

            const isArrayMatch = arrayMatch[1]

            if (isArrayMatch) {
                const isNamedArrayKeys = arrayMatch[2].match(/\D/)

                if (!values[arrayMatch[1]])
                    values[arrayMatch[1]] = isNamedArrayKeys
                        ? {}
                        : []

                if (isNamedArrayKeys)
                    values[arrayMatch[1]][arrayMatch[2]] = value

                if (!isNamedArrayKeys)
                    values[arrayMatch[1]].push(value)
            }

            if (!isArrayMatch)
                values[key] = value || ''

            return values
        }, {})

export const getHostPrimaryDomain = (host = window.location.host) => {
    const parts = (host || '')
        .replace(/:\d+$/, '')
        .split('.')

    return (
        parts.length > 2
            ? parts.slice(1)
            : parts
    ).join('.')
}
