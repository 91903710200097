//* ---------------------------------------
//  Components - Utility Functions

export const render = (
    obj = {},
    selectorPrefix = '',
    hmr = true
) => Object.keys(obj)
    .map(file => obj[file])
    .filter(component => component.default
        && component.selector)
    .map(component => Array
        .from(document.querySelectorAll(`
            ${selectorPrefix}${component.selector}`))
        .map((el) => {
            if (!hmr
                    && el._rendered)
                return el

            el._rendered = true

            component.default(el)

            return el
        }))
