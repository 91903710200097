//* ---------------------------------------
// Core - Shared fns

import { render } from './utils/components'

//* ---------------------------------------
// Components

export const components = import.meta
    .globEager('../components/!(cookie-banner|global-footer|global-header|link|offcanvas|select|typography-chars|wallcalculator)/**/*.js')

//* ---------------------------------------
//  Render

render(components)

if (('MutationObserver' in window))
    (new window.MutationObserver((e) =>
        render(components, '', false)
    )).observe(document.body, {
        childList: true,
        subtree: true
    })
