import ClipboardJS from 'clipboard'

export const selector = '.location-index-region'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Location Index')

    // Interactive Elements
    const clipboard = new ClipboardJS(el
        .querySelectorAll('[data-clipboard-text]'))

    const selectFilters = Array.from(el
        .querySelectorAll('.tab-pane select'))

    // Util fns
    const onClipboard = (e) => {
        const { trigger } = e

        if (!trigger
                || trigger.getAttribute('data-clipboard-status'))
            return

        const isError = !e.text

        trigger.style.minWidth = `${trigger.clientWidth}px`

        trigger.style.minHeight = `${trigger.clientHeight}px`

        trigger
            .setAttribute('data-clipboard-status', isError
                ? 'error'
                : 'success')

        trigger
            .setAttribute('data-title', trigger.getAttribute('title'))

        trigger
            .setAttribute('title', 'Copied')

        setTimeout(() => {
            trigger.style.minWidth = ''

            trigger.style.minHeight = ''

            trigger
                .setAttribute('title', trigger.getAttribute('data-title'))

            trigger
                .removeAttribute('data-title')

            trigger
                .removeAttribute('data-clipboard-status')
        }, 1000)
    }

    const onSelectFilterChange = (e) => {
        const selected = e.target.options[e.target.selectedIndex]

        const isReset = !selected.getAttribute('value')

        const isCountry = selected.getAttribute('data-country')

        Array.from(el
            .querySelectorAll('.tab-pane.active .location-index-region-list > li'))
            .forEach(_el => {
                const isShow = isReset
                    || (isCountry
                        && _el.getAttribute('data-country-code')
                            === e.target.value)
                    || (!isCountry
                        && _el.getAttribute('data-admin-level-1-slug')
                            === e.target.value)

                if (isShow && _el.style.display === 'none')
                    _el.style.display = ''

                if (!isShow && _el.style.display !== 'none')
                    _el.style.display = 'none'
            })

        const listLength = el
            .querySelector('.tab-pane.active .location-index-region-list-length')

        if (listLength)
            listLength.innerHTML = Array.from(el
                .querySelectorAll('.tab-pane.active .location-index-region-list > li'))
                .filter(_el => _el.style.display !== 'none')
                .length

        if (listLength)
            listLength.setAttribute('data-location-index-region-list-length',
                listLength.innerHTML)
    }

    // Events
    clipboard
        .on('success', onClipboard)

    clipboard
        .on('error', onClipboard)

    selectFilters
        .forEach(filter => filter
            .addEventListener('change', onSelectFilterChange))

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                selectFilters
                    .forEach(filter => filter
                        .removeEventListener('change', onSelectFilterChange))

                clipboard.destroy()
            })

    console.log(el)

    console.groupEnd()

    return el
}
