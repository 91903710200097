export const selector = '.hubspot-form'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('HubSpot Form')

    window.addEventListener('message', event => {
        if (event.data.type === 'hsFormCallback'
        && event.data.eventName === 'onFormReady')
            el.classList.remove('hubspot-form-loading')
    })

    // Log instance
    console.log(el)

    console.groupEnd()

    return el
}
