// DOM-centric Utility Functions
import animatedScrollTo from 'animated-scroll-to'
import { getCssVar } from './css'

export const createEvent = (
    type = null,
    eventInit = { bubbles: false, cancelable: true }
) => {
    if (!type)
        return null

    const isCustomEvent = typeof (eventInit || {}).detail === 'object'

    const isEventConstructor = (!isCustomEvent
            && typeof window.Event === 'function')
        || (isCustomEvent
            && typeof window.CustomEvent === 'function')

    const event = isEventConstructor
        ? (new window[`${isCustomEvent ? 'Custom' : ''}Event`](type, eventInit))
        : document.createEvent('HTMLEvents')

    if (!isEventConstructor)
        event.initEvent(type,
            (eventInit || {}).bubbles || false,
            (eventInit || {}).cancelable || true)

    if (isCustomEvent
            && !isEventConstructor)
        event.detail = eventInit.detail

    return event
}

export const isTouchDevice = () =>
    ('ontouchstart' in window
            && window.ontouchstart)
        || ('DocumentTouch' in window
            && document instanceof window.DocumentTouch)
        || ((window.navigator.maxTouchPoints || 0) > 0
            && !window.navigator.userAgent.match(/X11/))

export const detectScrollDirection = (
    el = document.documentElement,
    threshold
) => {
    if (!el)
        return

    const isTouch = isTouchDevice()

    if (threshold === undefined)
        threshold = isTouch
            ? 4
            : 0

    const getDiff = (prev, curr) => {
        let diff = prev - curr

        if (diff < 0)
            diff = diff * -1

        return diff
    }

    const onScroll = (e) => {
        const { _scrollPosition } = el

        if (typeof _scrollPosition !== 'number')
            return

        const scrollPosition = el.scrollTop

        const diff = getDiff(_scrollPosition, scrollPosition)

        if (threshold
                && diff < threshold)
            return

        el._scrollPosition = scrollPosition

        let direction

        if (_scrollPosition > scrollPosition)
            direction = 'up'

        if (_scrollPosition < scrollPosition)
            direction = 'down'

        if (direction
                && direction !== el.getAttribute('data-scrolldir'))
            el.setAttribute('data-scrolldir', direction)
    }

    const _el = el === document.documentElement
        ? window
        : el

    el._scrollPosition = _el.scrollTop || 0

    _el.addEventListener('scroll', onScroll)

    _el.addEventListener('touchmove', onScroll)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                _el.removeEventListener('scroll', onScroll)

                _el.removeEventListener('touchmove', onScroll)
            })
}

export const getScrollbarWidth = () => {
    const inner = document.createElement('p')
    const outer = document.createElement('div')

    inner.style.width = '100%'
    inner.style.height = '200px'

    outer.className = 'scrollbar-width-test'
    outer.style.position = 'absolute'
    outer.style.top = '-150px'
    outer.style.left = '-200px'
    outer.style.width = '200px'
    outer.style.height = '150px'
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll'

    outer.appendChild(inner)
    document.body.appendChild(outer)

    const width = outer.offsetWidth - inner.clientWidth
    document.body.removeChild(outer)

    return width
}

export const scrollTo = async (numberOrArrayOrElement, opts = {}) => {
    const isArray = Array.isArray(numberOrArrayOrElement)
        && numberOrArrayOrElement
            .filter(number => typeof number === 'number')
            .length === 2

    const isNumber = !isArray
        && typeof numberOrArrayOrElement === 'number'

    const scrollToCoords = isArray
        ? numberOrArrayOrElement
        : [0, isNumber ? numberOrArrayOrElement : 0]

    const el = (numberOrArrayOrElement || {}).nodeType
        ? numberOrArrayOrElement
        : null

    if (!isArray
            && !isNumber
            && !el)
        return Promise.resolve(false)

    document.activeElement.blur()

    if (el)
        scrollToCoords[1] = el.getBoundingClientRect().top
            + (window.scrollY || 0)

    const globalHeader = document.getElementById('global-header')

    const isNavbarRevealScrolldirUp = globalHeader
        && globalHeader.getAttribute(
            'data-designtex-global-header-navbar-fixed-top-reveal-scroll-up')
                === 'true'

    const scrollPaddingTop = parseFloat(
        window.getComputedStyle(document.documentElement)
            .scrollPaddingTop || '0') || 0

    const navbarFixedTopHeight = parseFloat(
        getCssVar('--designtex-global-header-navbar-fixed-top-height') || '0')

    const navbarFixedTopOffset = parseFloat(
        getCssVar('--designtex-global-header-navbar-fixed-top-offset') || '0')

    const globalHeaderHeight = parseFloat(
        getCssVar('--designtex-global-header-height') || '0')

    const scrollPaddingTopY = scrollToCoords[1] - scrollPaddingTop

    const scrollPaddingTopNoNavbar = scrollPaddingTop - navbarFixedTopHeight

    const navbarFixedTopY = Math
        .round(globalHeaderHeight
            - navbarFixedTopOffset
            + scrollPaddingTop
            - navbarFixedTopHeight
            + 0.5)

    const isScrollPaddingTopY = scrollPaddingTopY
        >= navbarFixedTopY

    if (isScrollPaddingTopY)
        scrollToCoords[1] = scrollPaddingTopY

    // Navbar always visible unless at top of page
    if (!isNavbarRevealScrolldirUp) {
        const isNavbarFixedTopY = !isScrollPaddingTopY
            && scrollToCoords[1] > navbarFixedTopY

        if (isNavbarFixedTopY)
            scrollToCoords[1] = navbarFixedTopY

        if (!isScrollPaddingTopY
                && !isNavbarFixedTopY)
            scrollToCoords[1] -= scrollPaddingTopNoNavbar
    }

    const navbarDisabledAttr
        = 'data-designtex-global-header-navbar-fixed-top-disabled'

    const isNavbarDisabled = globalHeader
        && globalHeader.getAttribute(navbarDisabledAttr) === 'true'

    const isNavbarScrolldirReveal = !isNavbarDisabled
        && isNavbarRevealScrolldirUp
        && window.scrollY > scrollToCoords[1]

    const isNavbarScrolldirRevealDisabled = isNavbarScrolldirReveal
        && scrollToCoords[1] - navbarFixedTopHeight <= globalHeaderHeight

    if (isNavbarScrolldirReveal
            && !isNavbarScrolldirRevealDisabled)
        scrollToCoords[1] -= navbarFixedTopHeight

    if (isNavbarScrolldirRevealDisabled
            && globalHeader)
        globalHeader.setAttribute(navbarDisabledAttr, true)

    const isSafari = window.navigator.userAgent.indexOf('Safari') >= 0
        && window.navigator.userAgent.indexOf('Chrome') === -1

    const focusEl = () => {
        if (!el)
            return

        if (el.tabIndex === -1
                && !el.getAttribute('tabindex'))
            el.setAttribute('tabindex', -1)

        el.focus({ preventScroll: true })
    }

    if (isSafari)
        focusEl()

    const isTouch = document.body.classList.contains('touch')

    if (isTouch
            && !('cancelOnUserAction' in opts))
        opts.cancelOnUserAction = false

    const scrollToOpts = Object.assign({
        easing: t => t < 0.5
            ? 4 * t * t * t
            : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        speed: 200
    }, opts)

    return animatedScrollTo(scrollToCoords, scrollToOpts)
        .then((done) => {
            if (isNavbarScrolldirRevealDisabled
                    && globalHeader)
                globalHeader.removeAttribute(navbarDisabledAttr)

            if (done
                    && !isSafari)
                focusEl()

            return done
        })
}
