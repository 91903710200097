import { getLocationSearchParams } from '../../scripts/utils/location'
import {
    getFormData,
    getUrlFromFormData
} from '../../scripts/utils/forms'

export const selector = '.case-studies-index'

export default (
    el = null
) => {
    if (!el
            || document.body.classList.contains('.wp-admin'))
        return el

    console.groupCollapsed('Case Studies Index')

    const form = el.querySelector('form.case-studies-index-filters')

    const offcanvasId = 'case-studies-index-offcanvas'

    const offcanvas = el.querySelector(`#${offcanvasId}`)

    const offcanvasToggle = el.querySelector('[data-bs-toggle=offcanvas]')

    const offcanvasBody = offcanvas
        && offcanvas.querySelector('.offcanvas-body')

    const caseStudiesList = el.querySelector('#case-studies-index-list')

    const getLinks = () => Array
        .from(el.querySelectorAll('.case-studies-index-list a'))

    const onLinkClick = async (e) => {
        if (!offcanvas
                || !offcanvasBody
                || !offcanvasToggle
                || (e || {}).ctrlKey)
            return

        e.preventDefault()

        offcanvasBody.innerHTML = ''

        offcanvasToggle.click()

        const caseStudyHTML = await window.fetch(e.target.href)
            .then(res => res.text())
            .catch(err => console.warn(err))

        if (!caseStudyHTML)
            return console.log('Handle empty fetch')

        const parser = new window.DOMParser()

        const caseStudy = parser
            .parseFromString(caseStudyHTML, 'text/html')

        const caseStudyMain = caseStudy
            .querySelector('main')

        if (!caseStudyMain)
            return

        const backBtn = caseStudyMain.querySelector('.back-to-gallery')

        if (backBtn) {
            backBtn.setAttribute('data-bs-dismiss', 'offcanvas')
            backBtn.setAttribute('data-bs-target', `#${offcanvasId}`)
        }

        Array.from(caseStudyMain.children || [])
            .forEach(child => offcanvasBody.appendChild(child))
    }

    const onListLoaded = (e) => {
        getLinks()
            .filter(link => !link._offcanvasListener)
            .forEach(link => {
                link._offcanvasListener = true

                link.addEventListener('click', onLinkClick)
            })
    }

    const onFormChange = (e) => {
        const url = getUrlFromFormData(getFormData(form))

        if (url === window.location.href)
            return

        window.history.pushState({
            scrollY: window.scrollY
        }, null, url)

        onHistoryChange()
    }

    const onHistoryChange = async (e) => {
        el.style.pointerEvents = 'none'

        el.style.minHeight = `${el.clientHeight}`

        caseStudiesList.classList.add('fade')

        if (caseStudiesList.classList.contains('show'))
            caseStudiesList.classList.remove('show')

        const isPopState = (e || {}).type === 'popstate'

        const params = isPopState
            ? getLocationSearchParams()
            : getFormData(form)

        const filterCheckboxes = Array.from(
            form.querySelectorAll('input[type=checkbox]'))

        filterCheckboxes
            .filter((input) => {
                const key = input.name.replace(/\[.*\]$/, '')

                return (input.checked
                        && (params[key] || []).indexOf(input.value) < 0)
                    || (!input.checked
                        && (params[key] || []).indexOf(input.value) >= 0)
            })
            .forEach(input => (input.checked = !input.checked))

        const fetchUrl = getUrlFromFormData(params)

        const caseStudyIndexHTML = await window.fetch(fetchUrl)
            .then(res => res.text())
            .catch(err => console.warn(err)
                && '')

        const parser = new window.DOMParser()

        const caseStudyIndex = parser
            .parseFromString(caseStudyIndexHTML, 'text/html')

        caseStudiesList.innerHTML = (caseStudyIndex
            .getElementById(caseStudiesList.id) || {})
            .innerHTML

        const newQueryPagination = caseStudyIndex
            .querySelector('.query-pagination')

        const oldQueryPagination = el
            .querySelector('.query-pagination')

        if (!newQueryPagination
                && oldQueryPagination)
            oldQueryPagination.parentElement
                .removeChild(oldQueryPagination)

        if (newQueryPagination
                && !oldQueryPagination)
            caseStudiesList.parentElement
                .appendChild(newQueryPagination)

        if (newQueryPagination
                && oldQueryPagination)
            oldQueryPagination.parentElement
                .replaceChild(newQueryPagination, oldQueryPagination)

        caseStudiesList.classList.add('show')

        onListLoaded()

        el.style.minHeight = ''

        el.style.pointerEvents = ''

        if (((e || {}).state || {}).scrollY)
            window.scrollTo(0, e.state.scrollY)
    }

    if (caseStudiesList)
        caseStudiesList.addEventListener(
            'end.loading-more.query-pagination', onListLoaded)

    if (form)
        form.addEventListener('change', onFormChange)

    window
        .addEventListener('load', () => {
            const href = decodeURI(window.location.href)
                .replace(/(\[\d+\]=)/g, '[]=')
                .replace(/(\?|&)doing_wp_cron=.+$/, '')

            if (!('history' in window)
                    || window.location.href === href)
                return

            window.history.replaceState(null, '', href)
        })

    window
        .addEventListener('popstate', onHistoryChange)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                if (form)
                    form.removeEventListener('change', onFormChange)

                getLinks()
                    .forEach(link => {
                        link._offcanvasListener = false

                        link.removeEventListener('click', onLinkClick)
                    })

                if (caseStudiesList)
                    caseStudiesList.removeEventListener(
                        'end.loading-more.query-pagination', onListLoaded)

                window.removeEventListener('popstate', onHistoryChange)
            })

    onListLoaded()

    console.log(el)

    console.groupEnd()

    return el
}
