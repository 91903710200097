import Swiper from 'swiper'
import { A11y, Navigation, Pagination } from 'swiper/modules'

export const selector = '.hero-carousel-swiper'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Hero Carousel Swiper')

    const heroCarouselSwiper = new Swiper(el, {
        modules: [A11y, Navigation, Pagination],
        spaceBetween: 0,
        slidesPerView: 1,
        speed: 300,
        navigation: {
            nextEl: '.hero-carousel-swiper-button-next',
            prevEl: '.hero-carousel-swiper-button-prev'
        },
        pagination: {
            el: '.hero-carousel-swiper-pagination',
            type: 'fraction',
            clickable: false
        }
    })

    // Log instance
    console.log(heroCarouselSwiper)

    console.groupEnd()

    return heroCarouselSwiper
}
