import Swiper from 'swiper'
import { A11y, EffectFade, Navigation, Pagination } from 'swiper/modules'

export const selector = '.asset-metrics-swiper'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Asset + Metrics Swiper')

    const navEl = el.querySelector('.asset-metrics-swiper-nav')

    const checkTheme = (element) => {
        const regexCss = /\btext-bg-theme\S*/i

        return Array.from(element.classList)
            .filter((css) => regexCss.test(css))[0]
    }

    let theme = navEl
        ? checkTheme(navEl)
        : null

    const assetMetricsSwiper = new Swiper(el, {
        modules: [A11y, EffectFade, Navigation, Pagination],
        loop: true,
        spaceBetween: 0,
        speed: 300,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        slidesPerView: '1',
        pagination: {
            el: '.asset-metrics-swiper-pagination',
            type: 'fraction',
            clickable: false
        },
        navigation: {
            nextEl: '.asset-metrics-swiper-button-next',
            prevEl: '.asset-metrics-swiper-button-prev'
        }
    })

    assetMetricsSwiper.on('slideChange', function () {
        const current = assetMetricsSwiper.visibleSlides

        if (!navEl
                && !current
                && !current[0])
            return

        const item = current[0].querySelector('.asset-metrics-item')

        if (theme)
            navEl.classList.remove(theme)

        theme = checkTheme(item)

        if (theme)
            navEl.classList.add(theme)
    })

    // Log instance
    console.log(assetMetricsSwiper)

    console.groupEnd()

    return assetMetricsSwiper
}
