// External library
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const selector = '[data-animate="scrolling-asset-text"]'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Scrolling Asset + Text')

    const sections = gsap.utils.toArray(
        el.querySelectorAll('.scrolling-asset-text-item')
    )

    const sectionsWrapper = el.querySelector('.scrolling-asset-text-wrapper')

    // If there's less than two sections
    if (!sections || sections.length < 2)
        return

    // Save styles to be restored in mobile
    ScrollTrigger.saveStyles([
        '.scrolling-asset-text-item',
        '.scrolling-asset-text-wrapper',
        '.scrolling-asset-text-content',
        '.scrolling-asset-text-image'
    ])

    const matchMedia = gsap.matchMedia()

    matchMedia.add('(min-width: 1240px)', () => {
        const height = 100 * sections.length

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: 'top top',
                end: `+=${height}%`,
                scrub: true
            }
        })

        gsap.timeline({
            scrollTrigger: {
                trigger: sectionsWrapper,
                start: 'top top',
                end: `+=${height}%`,
                pin: sectionsWrapper,
                scrub: true,
                pinSpacing: true
            }
        })

        sections.forEach((el, i) => {
            const mediaEl = el.querySelector('.scrolling-asset-text-image')
            const textEl = el.querySelector('.scrolling-asset-text-content')

            gsap.set(el, {
                position: 'absolute',
                top: 0
            })

            timeline.from(textEl, {
                autoAlpha: 0,
                y: '30%'
            }, i)

            if (i)
                timeline.from(mediaEl, {
                    autoAlpha: 0
                }, i)

            if (!i) {
                const timing = i + 0.7

                timeline
                    .to(mediaEl, {
                        autoAlpha: 0
                    }, timing)
                    .to(textEl, {
                        autoAlpha: 0,
                        y: '-90%',
                        ease: 'easeIn'
                    }, timing)
            }
        })
    })

    // Log instance
    console.log(sections)

    console.groupEnd()

    return el
}
