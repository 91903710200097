export const selector = '.accordions'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Accordions')

    const accordionTabs = Array.from(el
        .querySelectorAll('.tab-pane'))

    accordionTabs
        .forEach((tab) => {
            const toggleAll = tab
                .querySelector('.accordions-toggle')

            const collapsibles = Array.from(tab
                .querySelectorAll('.accordion-collapse'))

            const collapseToggles = Array.from(tab
                .querySelectorAll('[data-bs-toggle=collapse]'))

            const isAllOpened = () => !collapseToggles
                .filter(toggle => toggle.getAttribute('aria-expanded')
                    === 'false')
                .length

            const onToggleAll = (e) => {
                const isClose = isAllOpened()

                collapseToggles
                    .forEach((toggle) => {
                        if ((isClose
                                    && toggle.getAttribute('aria-expanded')
                                        !== 'false')
                                || (!isClose
                                    && toggle.getAttribute('aria-expanded')
                                        !== 'true'))
                            toggle.click()
                    })

                e.target.focus({ preventScroll: true })
            }

            if (toggleAll)
                toggleAll.addEventListener('click', onToggleAll)

            const expandedText = (toggleAll
                && toggleAll.getAttribute('data-expanded-text'))
                    || 'Close all'

            const collapsedText = (toggleAll
                && toggleAll.getAttribute('data-collapsed-text'))
                    || 'Open all'

            const onCollapseToggle = (e) => setTimeout(() => {
                if (!toggleAll)
                    return

                if (isAllOpened()
                        && toggleAll.innerText !== expandedText)
                    toggleAll.innerText = expandedText

                if (!isAllOpened()
                        && toggleAll.innerText !== collapsedText)
                    toggleAll.innerText = collapsedText
            }, 0)

            collapsibles
                .forEach((collapsible) => {
                    collapsible
                        .addEventListener('show.bs.collapse', onCollapseToggle)

                    collapsible
                        .addEventListener('hide.bs.collapse', onCollapseToggle)
                })

            if (import.meta.hot)
                import.meta.hot
                    .on('vite:beforeUpdate', () => {
                        if (toggleAll)
                            toggleAll
                                .removeEventListener('click', onToggleAll)

                        collapsibles
                            .forEach((collapsible) => {
                                collapsible
                                    .removeEventListener('show.bs.collapse',
                                        onCollapseToggle)

                                collapsible
                                    .removeEventListener('hide.bs.collapse',
                                        onCollapseToggle)
                            })
                    })
        })

    const getNavBtnIconWidth = () => {
        const icon = el.querySelector('.nav .btn.active .btn-icon')

        if (!icon)
            return 0

        return icon.clientWidth
            + parseFloat(window.getComputedStyle(icon).marginRight || '0')
    }

    const nav = el.querySelector('.nav')

    const getNavItems = () => (nav
        && Array.from(nav.querySelectorAll('li')))
            || []

    getNavItems()
        .forEach((item) => {
            const iconWidth = getNavBtnIconWidth()

            const isActive = Boolean(item.querySelector('.btn.active'))

            item.setAttribute('data-active-width',
                isActive
                    ? item.clientWidth
                    : item.clientWidth + iconWidth)

            item.setAttribute('data-inactive-width',
                isActive
                    ? item.clientWidth - iconWidth
                    : item.clientWidth)
        })

    let navClone = el.querySelector('.nav-clone')

    if (!navClone
            && nav) {
        navClone = nav.cloneNode(true)

        navClone.classList.add('nav-clone')

        navClone.role = ''

        navClone.setAttribute('aria-hidden', true)

        navClone.style.position = 'absolute'
        navClone.style.visibility = 'hidden'
        navClone.style.width = '100%'

        Array.from(navClone.querySelectorAll('[data-bs-toggle=pill]'))
            .forEach((pill) => {
                pill.setAttribute('data-id', pill.getAttribute('id'))

                pill.id = ''
                pill.role = ''

                pill.classList.remove('active')

                pill.removeAttribute('data-bs-toggle')
                pill.removeAttribute('data-bs-target')
                pill.removeAttribute('aria-controls')
                pill.removeAttribute('aria-selected')
            })

        nav.parentElement
            .insertBefore(navClone, nav)
    }

    const getNavCloneItems = () => (navClone
        && Array.from(navClone.querySelectorAll('li')))
            || []

    const setNavItemsSizes = () => {
        if (!navClone)
            return

        getNavItems()
            .forEach((item, idx) => {
                if (item.style.minWidth)
                    item.style.minWidth = ''

                if (item.style.maxWidth)
                    item.style.maxWidth = ''
            })

        const navCloneBounds = navClone.getBoundingClientRect()

        const navCloneItems = getNavCloneItems()

        navCloneItems
            .forEach((item, idx) => {
                if (item.style.minWidth)
                    item.style.minWidth = ''

                if (item.style.maxWidth)
                    item.style.maxWidth = ''
            })

        navCloneItems
            .forEach((item, idx) => {
                if (!idx)
                    return

                const { top } = item.getBoundingClientRect()

                const rowPrevItems = navCloneItems.slice(0, idx)
                    .filter(_item =>
                        _item.getBoundingClientRect().top === top)

                if (!rowPrevItems.length)
                    return

                const rowPrevItemsMinWidth = rowPrevItems
                    .reduce((acc, curr) => {
                        return acc + parseFloat(curr
                            .getAttribute('data-inactive-width') || '0')
                    }, 0)

                const rowPrevItemsMaxWidth = rowPrevItemsMinWidth
                    + getNavBtnIconWidth()

                const itemActiveWidth = parseFloat(
                    item.getAttribute('data-active-width') || '0')

                const rowItemsMinWidth = rowPrevItemsMinWidth + itemActiveWidth

                if (rowItemsMinWidth <= navCloneBounds.width
                        || rowPrevItemsMaxWidth > navCloneBounds.width)
                    return

                const maxWidth = Math.floor(navCloneBounds.width
                    - rowPrevItems.slice(0, rowPrevItems.length - 1)
                        .reduce((acc, curr) => {
                            return acc + parseFloat(curr
                                .getAttribute('data-inactive-width') || '0')
                        }, 0))

                const minWidth = maxWidth - getNavBtnIconWidth() - 1

                const prevCloneItem = item.previousElementSibling

                const prevCloneItemBtn = prevCloneItem
                    && prevCloneItem.querySelector('[data-id]')

                const prevItemBtn = prevCloneItemBtn
                    && el.querySelector(
                        `#${prevCloneItemBtn.getAttribute('data-id')}`)

                const prevItem = prevItemBtn
                    && prevItemBtn.parentElement

                if (prevCloneItem.style.minWidth !== `${minWidth}px`)
                    prevCloneItem.style.minWidth = `${minWidth}px`

                if (prevCloneItem.style.maxWidth !== `${maxWidth}px`)
                    prevCloneItem.style.maxWidth = `${maxWidth}px`

                if (prevItem
                        && prevItem.style.minWidth !== `${minWidth}px`)
                    prevItem.style.minWidth = `${minWidth}px`

                if (prevItem
                        && prevItem.style.maxWidth !== `${maxWidth}px`)
                    prevItem.style.maxWidth = `${maxWidth}px`

                console.log(prevItemBtn, prevCloneItemBtn)
                console.log(minWidth, maxWidth)
            })
    }

    setNavItemsSizes()

    window.removeEventListener('resize', setNavItemsSizes)
    window.addEventListener('resize', setNavItemsSizes)

    // Log els
    console.log(el)
    console.groupEnd()

    return el
}
