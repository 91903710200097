import { getLocationSearchParams } from '../../scripts/utils/location'

export const selector = '.partner-index-query'

export default (
    el = null
) => {
    if (!el
            || document.body.classList.contains('.wp-admin'))
        return el

    console.groupCollapsed('Partner Index Query')

    const baseUrl = el.getAttribute('data-base-url')

    const taxonomyBaseUrl = el.getAttribute('data-taxonomy-base-url')

    const resultsList = el.querySelector('#partner-index-query-results')

    const taxonomyFilterBtn = el
        .querySelector('.partner-index-query-filters-taxonomy a')

    const getFilterLinks = () => Array.from(el
        .querySelectorAll('.partner-index-query-filters-starts-with a'))
        .concat([taxonomyFilterBtn])
        .filter(link => link)

    const getUrlParts = (url = '') => url.split('?')

    const onFilterLinkClick = (e) => {
        e.preventDefault()

        let href = e.target.href

        const hrefParts = getUrlParts(href)

        const queryString = hrefParts[1] || ''

        const params = getLocationSearchParams(queryString) || {}

        if (href === window.location.href
                && params.starts_with)
            href = hrefParts[0]

        if (href === window.location.href)
            return

        window.history.pushState({
            scrollY: window.scrollY
        }, null, href)

        onHistoryChange()
    }

    const onHistoryChange = async (e) => {
        el.style.pointerEvents = 'none'

        el.style.minHeight = `${el.clientHeight}`

        const noResults = resultsList.parentElement
            .querySelector('[role=status]')

        if (noResults
                    && noResults.style.display !== 'none') {
            noResults.classList.add('fade')

            if (noResults.classList.contains('show'))
                noResults.classList.remove('show')
        }

        resultsList.classList.add('fade')

        if (resultsList.classList.contains('show'))
            resultsList.classList.remove('show')

        const params = getLocationSearchParams()

        const isTaxonomyFilterActive = window.location.href
            .indexOf(taxonomyBaseUrl) === 0

        if (taxonomyFilterBtn) {
            let taxonomyFilterBtnHref = isTaxonomyFilterActive
                ? baseUrl
                : taxonomyBaseUrl

            if (params.starts_with)
                taxonomyFilterBtnHref += `?starts_with=${params.starts_with}`

            if (taxonomyFilterBtn.href !== taxonomyFilterBtnHref)
                taxonomyFilterBtn.href = taxonomyFilterBtnHref

            if (isTaxonomyFilterActive
                    && !taxonomyFilterBtn.getAttribute('aria-current')) {
                taxonomyFilterBtn.setAttribute('aria-current', true)
                taxonomyFilterBtn.classList.remove('btn-outline-primary')
                taxonomyFilterBtn.classList.add('btn-primary')
            }

            if (!isTaxonomyFilterActive
                    && taxonomyFilterBtn.getAttribute('aria-current')) {
                taxonomyFilterBtn.removeAttribute('aria-current')
                taxonomyFilterBtn.classList.remove('btn-primary')
                taxonomyFilterBtn.classList.add('btn-outline-primary')
            }
        }

        getFilterLinks()
            .filter(link => link !== taxonomyFilterBtn)
            .forEach((link) => {
                let queryString = getUrlParts(link.href)[1] || ''

                if (queryString)
                    queryString = `?${queryString}`

                const href = `${isTaxonomyFilterActive
                    ? taxonomyBaseUrl
                    : baseUrl}${queryString}`

                if (link.href !== href)
                    link.href = href

                if (link.href === window.location.href
                        && link.getAttribute('aria-current') !== 'true')
                    link.setAttribute('aria-current', true)

                if (link.href !== window.location.href
                        && link.getAttribute('aria-current'))
                    link.removeAttribute('aria-current')
            })

        const partnersIndexHTML = await window.fetch(window.location.href)
            .then(res => res.text())
            .catch(err => console.warn(err)
                && '')

        const parser = new window.DOMParser()

        const partnersIndex = parser
            .parseFromString(partnersIndexHTML, 'text/html')

        resultsList.innerHTML = (partnersIndex
            .getElementById(resultsList.id) || {})
            .innerHTML

        const newQueryPagination = partnersIndex
            .querySelector('.query-pagination')

        const oldQueryPagination = el
            .querySelector('.query-pagination')

        if (!newQueryPagination
                && oldQueryPagination)
            oldQueryPagination.parentElement
                .removeChild(oldQueryPagination)

        if (newQueryPagination
                && !oldQueryPagination)
            resultsList.parentElement
                .appendChild(newQueryPagination)

        if (newQueryPagination
                && oldQueryPagination)
            oldQueryPagination.parentElement
                .replaceChild(newQueryPagination, oldQueryPagination)

        if (noResults
                && !resultsList.children.length) {
            noResults.style.display = ''
            noResults.classList.add('show')
        }

        if (noResults
                && resultsList.children.length) {
            noResults.style.display = 'none'
            noResults.classList.remove('show')
        }

        setTimeout(() => resultsList.classList.add('show'), 1)

        el.style.minHeight = ''

        el.style.pointerEvents = ''

        if (((e || {}).state || {}).scrollY)
            window.scrollTo(0, e.state.scrollY)
    }

    getFilterLinks()
        .forEach(link => link.addEventListener('click', onFilterLinkClick))

    window
        .addEventListener('load', () => {
            const href = decodeURI(window.location.href)
                .replace(/(\[\d+\]=)/g, '[]=')
                .replace(/(\?|&)doing_wp_cron=.+$/, '')

            if (!('history' in window)
                    || window.location.href === href)
                return

            window.history.replaceState(null, '', href)
        })

    window
        .addEventListener('popstate', onHistoryChange)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                getFilterLinks()
                    .forEach(link => link.removeEventListener(
                        'click', onFilterLinkClick))

                window.removeEventListener('popstate', onHistoryChange)
            })

    console.log(el)

    console.groupEnd()

    return el
}
