// External library
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const selector = '[data-animate="image-text-reveal"]'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Image + Text - Reveal')

    const sections = gsap.utils.toArray(
        el.querySelectorAll('.image-text-item')
    )

    if (!sections)
        return

    // Save styles to be restored in mobile
    ScrollTrigger.saveStyles(['.image-text-content'])

    const matchMedia = gsap.matchMedia()

    matchMedia.add('(min-width: 1240px)', () => {
        sections.forEach((item) => {
            const textEl = item.querySelector('.image-text-content')

            gsap.set(textEl, {
                y: '-50px',
                opacity: 0
            })

            gsap.to(textEl, {
                y: 0,
                opacity: 1,
                ease: 'expo',
                overwrite: true,
                scrollTrigger: {
                    trigger: item,
                    start: 'top center',
                    once: true,
                    scrub: true
                }
            })
        })
    })

    // Log instance
    console.log(sections)

    console.groupEnd()

    return el
}
