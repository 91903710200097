export const getFormData = (form) => {
    if (!form)
        return {}

    const obj = (form
        && Object.fromEntries(new window.FormData(form)
            .entries()))
                || {}

    const checked = Array.from(
        form.querySelectorAll('input[type=checkbox]:checked'))

    checked
        .filter(input => input.name && input.value)
        .forEach((input) => {
            const arrayMatch = input.name
                .match(/^(.+)\[([^\]]*)\]$/) || []

            const isArrayMatch = arrayMatch[1]

            if (isArrayMatch) {
                const isNamedArrayKeys = arrayMatch[2].match(/\D/)

                if (!obj[arrayMatch[1]])
                    obj[arrayMatch[1]] = isNamedArrayKeys
                        ? {}
                        : []

                if (isNamedArrayKeys)
                    obj[arrayMatch[1]][arrayMatch[2]] = input.value

                if (!isNamedArrayKeys)
                    obj[arrayMatch[1]].push(input.value)
            }

            if (!isArrayMatch)
                obj[input.name] = input.value || ''
        })

    Object.keys(obj)
        .filter(key => key.match(/\[.*\]$/))
        .forEach(key => (delete obj[key]))

    return obj
}

export const getUrlFromFormData = (
    formData = {},
    locationHref = window.location.href
        .replace(/(?:\/page\/\d*)*\?.*$/, '')
) => {
    let href = locationHref

    const queryString = Object.keys(formData || {})
        .map(key => Array.isArray(formData[key])
            ? formData[key]
                .map(str => `${key}[]=${str}`)
                .join('&')
            : `${key}=${formData[key]}`)
        .join('&')
        .trim()

    if (queryString)
        href += `?${queryString}`

    return href
}
