import Swiper from 'swiper'
import { A11y, FreeMode, Navigation } from 'swiper/modules'

export const selector = '.carousel-swiper'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Carousel Swiper')

    const getHotspotToggleBtns = () => Array.from(el
        .querySelectorAll('.hotspots-item [data-bs-toggle=tooltip]'))

    let isPrevBtnDisabled = false

    const isLoopOn = el
        .hasAttribute('data-carousel-loop')

    const carouselSwiper = new Swiper(el, {
        modules: [A11y, FreeMode, Navigation],
        slidesPerView: 'auto',
        lazyPreloadPrevNext: 1,
        freeMode: {
            enabled: true,
            sticky: false
        },
        breakpoints: {
            992: {
                freeMode: false,
                loop: isLoopOn,
                loopAdditionalSlides: 1,
                speed: 1200
            }
        },
        navigation: {
            nextEl: '.carousel-swiper-button-next',
            prevEl: '.carousel-swiper-button-prev'
        }
    })

    const onSwiperTranslate = () => getHotspotToggleBtns()
        .filter(btn => btn.getAttribute('aria-describedby'))
        .map(btn => window.bootstrap.Tooltip.getInstance(btn))
        .forEach(instance => instance
            && instance.hide())

    const disablePrevBtn = (disabled) => {
        const prevBtn = carouselSwiper.navigation.prevEl[0]

        if (!prevBtn)
            return

        carouselSwiper.allowSlidePrev = !disabled
        prevBtn.disabled = disabled
        prevBtn.setAttribute('tabindex', disabled
            ? -1
            : 0)

        isPrevBtnDisabled = disabled
    }

    carouselSwiper
        .on('setTranslate slideChange sliderMove',
            onSwiperTranslate)

    if (isLoopOn & window.innerWidth >= 992) {
        disablePrevBtn(true)

        carouselSwiper
            .on('slideNextTransitionStart', () => {
                if (isPrevBtnDisabled)
                    disablePrevBtn(false)
            })
    }

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                carouselSwiper
                    .off('setTranslate slideChange sliderMove',
                        onSwiperTranslate)
            })

    // Log instance
    console.log(carouselSwiper)

    console.groupEnd()

    return carouselSwiper
}
