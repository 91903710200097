import { createEvent } from '../../scripts/utils/dom'

export const selector = '.query-pagination'

export default (
    el = null
) => {
    if (!el)
        return

    // Constants
    const loadMoreSelector = el.getAttribute('data-load-more')

    // Els
    const loadMoreBtn = el.querySelector('.query-pagination-load-more-btn')

    const paginationNav = el.querySelector('.query-pagination-nav')

    // Utils
    const getNavNextLink
        = () => el.querySelector('.query-pagination-arrow-next')

    const getLoadMoreEl
        = (_el = document) => _el.querySelector(loadMoreSelector)

    const isLoaded = () => loadMoreBtn
        .getAttribute('data-loaded')
            === 'true'

    const isLoadingMore = () => loadMoreBtn
        .getAttribute('data-loading-more')
            === 'true'

    const isInfiniteScroll = () => el
        .getAttribute('data-infinite-scroll')
            === 'true'
        && !document.body.classList.contains('wp-admin')

    if (!loadMoreSelector
            || !loadMoreBtn
            || !getLoadMoreEl())
        return

    console.groupCollapsed('Query Pagination')

    console.log(getNavNextLink())

    // Events
    const onLoadMoreBtnClick = async (e) => {
        if (isLoaded()
                || isLoadingMore()
                || loadMoreBtn.disabled)
            return

        const { href } = getNavNextLink() || {}

        const getEvent = (type) =>
            createEvent(`${type}.query-pagination`, {
                detail: {
                    href
                }
            })

        const dispatchEvent = (type) => getLoadMoreEl()
            && getLoadMoreEl().dispatchEvent(getEvent(type))

        const setLoadMoreBtnState = (value) => value
            ? loadMoreBtn.setAttribute('data-loading-more', Boolean(value))
            : loadMoreBtn.removeAttribute('data-loading-more')

        const setLoadMoreBtnStateLoaded = () => {
            dispatchEvent('end.loading-more')

            dispatchEvent('loaded')

            loadMoreBtn.disabled = true

            loadMoreBtn.setAttribute('data-loaded', true)

            el.style.height = `${loadMoreBtn.clientHeight}px`

            el.setAttribute('data-loaded', true)

            setLoadMoreBtnState()
        }

        dispatchEvent('start.loading-more')

        setLoadMoreBtnState(true)

        loadMoreBtn.style.minWidth = `${loadMoreBtn.clientWidth}px`

        const nextPageHTML = href
            && await window.fetch(href)
                .then((res) => res.text())

        if (!nextPageHTML)
            return setLoadMoreBtnStateLoaded()

        const parser = new window.DOMParser()

        const nextPage = parser
            .parseFromString(nextPageHTML, 'text/html')

        const loadMoreEl = getLoadMoreEl(nextPage)

        const loadMoreElItems = Array.from((loadMoreEl || {}).children || [])

        if (!(loadMoreElItems || []).length
                && !isInfiniteScroll())
            return setLoadMoreBtnStateLoaded()

        const nextPageNav = nextPage
            .querySelector('.query-pagination-nav') || {}

        if (paginationNav)
            paginationNav.innerHTML = nextPageNav.innerHTML || ''

        const firstTabbableAnchor = loadMoreEl
            && loadMoreEl
                .querySelector('a[href]:not([tabindex="-1"])')

        if (firstTabbableAnchor)
            firstTabbableAnchor
                .setAttribute('data-query-pagination-page-href', href)

        loadMoreElItems
            .forEach(item => {
                item.classList.add('fade')

                getLoadMoreEl()
                    .appendChild(item)
            })

        setTimeout(() => {
            const _loadMoreEl = getLoadMoreEl()

            if (!_loadMoreEl)
                return setLoadMoreBtnStateLoaded()

            const items = Array.from((_loadMoreEl || {}).children || [])

            items
                .filter(item => !item.classList.contains('show'))
                .forEach(item => item.classList.add('show'))

            if (!firstTabbableAnchor
                    || isInfiniteScroll())
                return

            const _el = _loadMoreEl
                .querySelector(
                    `[data-query-pagination-page-href="${href}"]`)

            if (_el)
                _el.focus({ preventScroll: true })
        }, 1)

        if (!(getNavNextLink() || {}).href)
            return setLoadMoreBtnStateLoaded()

        setLoadMoreBtnState()

        dispatchEvent('end.loading-more')
    }

    const onScroll = (e) => {
        if (!loadMoreBtn
                || !isInfiniteScroll()
                || isLoadingMore())
            return

        const { top } = loadMoreBtn.getBoundingClientRect()

        const topOffset = top - (window.innerHeight * 0.5)

        const isLoadMore = (top >= 0
                && top <= window.innerHeight)
            || (topOffset >= 0
                && topOffset <= window.innerHeight)

        if (isLoadMore)
            onLoadMoreBtnClick()
    }

    const onEndLoadingMore = () => {
        if (!isInfiniteScroll())
            return

        setTimeout(() => onScroll(), 17)
    }

    const onLoaded = (e) => window
        .removeEventListener('scroll', onScroll)

    loadMoreBtn
        .addEventListener('click', onLoadMoreBtnClick)

    if (isInfiniteScroll()) {
        window.addEventListener('scroll', onScroll)

        window.addEventListener('resize', onScroll)

        getLoadMoreEl()
            .addEventListener('end.loading-more.query-pagination',
                onEndLoadingMore)
    }

    getLoadMoreEl()
        .addEventListener('loaded.query-pagination', () =>
            onLoaded)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                loadMoreBtn
                    .removeEventListener('click', onLoadMoreBtnClick)

                window.removeEventListener('scroll', onScroll)

                window.removeEventListener('resize', onScroll)

                if (!getLoadMoreEl())
                    return

                getLoadMoreEl()
                    .removeEventListener('end.loading-more.query-pagination',
                        onEndLoadingMore)

                getLoadMoreEl()
                    .removeEventListener('loaded.query-pagination', () =>
                        onLoaded)
            })

    onScroll()

    console.log(el)

    console.groupEnd()

    return el
}
