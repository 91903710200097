import ClipboardJS from 'clipboard'
import { getFormData } from '../../scripts/utils/forms'
import { getLocationSearchParams } from '../../scripts/utils/location'

export const selector = '.resource-index'

export default (
    el = null
) => {
    if (!el)
        return el

    console.groupCollapsed('Resource Index')

    // Interactive Elements
    const searchForm = el.querySelector('#resource-index-search')

    const searchCheckboxes = Array.from(searchForm
        ? searchForm.querySelectorAll('input[type=checkbox]')
        : [])
        .concat(Array.from(el.querySelectorAll(
            '.resource-index-filters-lg input[type=checkbox]')))

    const modalFilters = el.querySelector('form .modal-filters')

    const modalFiltersClearBtns = (modalFilters
        && Array.from(modalFilters.querySelectorAll('.btn-clear')))
            || []

    const resultsForm = el.querySelector('#resource-index-results-form')

    const resultsFormCtas = el
        .querySelector('.resource-index-results-form-ctas')

    const resultsFormCtasClearBtn = resultsFormCtas
        && resultsFormCtas.querySelector(
            '.resource-index-results-form-ctas-btn-clear')

    const resultsFormCtasSubmitBtn = resultsFormCtas
        && resultsFormCtas.querySelector(
            'button[type=submit]')

    const resultsList = el.querySelector('#resource-index-results-list')

    // Util fns
    const getClipboardItems = () => Array.from(el
        .querySelectorAll('[data-clipboard-text]'))

    const setResultsFromQueryString = () => {
        const params = getLocationSearchParams()

        console.log(params)
    }

    const setHistoryFromFormData = () => {
        console.log(getFormData(searchForm))

        setResultsFromQueryString()
    }

    // Events
    const onClipboard = (e) => {
        const { trigger } = e

        if (!trigger
                || trigger.getAttribute('data-clipboard-status'))
            return

        const isError = !e.text

        // Remove after tooltip status labels are in place
        console.log(trigger.getAttribute('data-clipboard-text'), trigger)

        trigger
            .setAttribute('data-clipboard-status', isError
                ? 'error'
                : 'success')

        trigger
            .setAttribute('data-title', trigger.getAttribute('title'))

        trigger
            .setAttribute('title', 'Copied')

        setTimeout(() => {
            trigger
                .setAttribute('title', trigger.getAttribute('data-title'))

            trigger
                .removeAttribute('data-title')

            trigger
                .removeAttribute('data-clipboard-status')
        }, 400)
    }

    const onSearchFormSubmit = (e) => {
        console.log(e)

        setHistoryFromFormData()
    }

    const onSearchCheckboxClick = (e) => {
        if (window.innerWidth < 992)
            return

        const { checked, name, value } = e.target || {}

        if (!name || !value)
            return

        const inputs = Array.from(el
            .querySelectorAll(`input[type=checkbox][name="${
                name}"][value="${value}"]`))

        inputs
            .filter(input => input !== e.target)
            .forEach(input => (input.checked = checked))

        if (searchForm)
            searchForm.submit()
    }

    const onClearModalFiltersBtnClick = (e) => {
        if (!modalFilters)
            return

        Array.from(modalFilters.querySelectorAll('input[type=checkbox]:checked'))
            .forEach(input => (input.checked = false))
    }

    const onShowModalFilters = (e) => {
        const params = getLocationSearchParams()

        searchCheckboxes
            .filter(input => (input.checked
                    && (params.resource_type || []).indexOf(input.value) < 0)
                || (!input.checked
                    && (params.resource_type || []).indexOf(input.value) >= 0))
            .forEach(input => (input.checked = !input.checked))
    }

    const onResultsFormChange = (e) => {
        if ((e || {}).target) {
            const bindedInputAttr = e.target.name
                ? 'data-name'
                : 'name'

            const bindedInputName = e.target.name
                || e.target.getAttribute('data-name')

            const bindedInput = resultsForm.querySelector(`input[${
                bindedInputAttr}="${bindedInputName}"]`)

            if (bindedInput)
                bindedInput.checked = e.target.checked
        }

        const checkedLength = Array.from(
            resultsForm.querySelectorAll('input[name][type=checkbox]:checked'))
            .length

        const checkedCountEls = Array
            .from(el.querySelectorAll(
                '[data-results-form-items-checked-count]'))

        checkedCountEls
            .forEach(item => item
                .setAttribute('data-results-form-items-checked-count',
                    checkedLength))

        if (!resultsFormCtas)
            return

        Array.from(resultsFormCtas.querySelectorAll('button'))
            .forEach(btn => (btn.disabled = !checkedLength))
    }

    const onResultsFormSubmit = async (e) => {
        e.preventDefault()

        if (resultsForm.getAttribute('data-downloading'))
            return

        resultsForm.setAttribute('data-downloading', true)

        if (resultsFormCtasSubmitBtn)
            resultsFormCtasSubmitBtn.setAttribute('data-downloading', true)

        const data = getFormData(resultsForm)

        await window
            .fetch(resultsForm.action, {
                method: 'POST',
                'Content-Type': 'application/json',
                body: JSON.stringify(data)
            })
            .then(res => res.blob())
            .then((blob) => {
                const anchor = document.createElement('a')

                anchor.href = window.URL.createObjectURL(blob)

                anchor.download = `designtex-${
                    Math.round((new Date()).getTime() / 1000)}.zip`

                document.body.appendChild(anchor)

                const activeEl = document.activeElement

                anchor.click()

                document.body.removeChild(anchor)

                activeEl.focus()
            })
            .catch(err => console.warn(err)
                && err)

        resultsForm.removeAttribute('data-downloading')

        if (resultsFormCtasSubmitBtn)
            resultsFormCtasSubmitBtn.removeAttribute('data-downloading')
    }

    const onResultsFormClearBtnClick = (e) => {
        const checked = Array.from(
            resultsForm.querySelectorAll('input[type=checkbox]:checked'))

        checked
            .forEach(input => (input.checked = false))

        onResultsFormChange()
    }

    const onResultsLoaded = (e) => {
        const list = (e || {}).target || resultsList || {}

        const items = Array.from(list.children || [])

        items
            .filter(item => item.classList
                .contains('resource-index-results-list-item-empty'))
            .forEach(item => item.parentElement.removeChild(item))

        const itemCountEls = [el].concat(Array
            .from(el.querySelectorAll('[data-results-list-items-count]')))

        itemCountEls
            .forEach(item => item
                .setAttribute('data-results-list-items-count',
                    (list.children || []).length))

        if (!el.getAttribute('data-results-ready'))
            el.setAttribute('data-results-ready', true)

        getClipboardItems()
            .filter(item => !item.clipboard)
            .forEach((item) => {
                item.clipboard = new ClipboardJS(item)

                item.clipboard
                    .on('success', onClipboard)

                item.clipboard
                    .on('error', onClipboard)
            })
    }

    searchCheckboxes
        .forEach((input) =>
            input.addEventListener('click', onSearchCheckboxClick))

    modalFiltersClearBtns
        .forEach((btn) =>
            btn.addEventListener('click', onClearModalFiltersBtnClick))

    if (modalFilters)
        modalFilters.addEventListener(
            'show.bs.modal', onShowModalFilters)

    if (searchForm)
        searchForm.addEventListener('submit', onSearchFormSubmit)

    if (resultsList)
        resultsList.addEventListener(
            'end.loading-more.query-pagination', onResultsLoaded)

    if (resultsFormCtasClearBtn)
        resultsFormCtasClearBtn.addEventListener(
            'click', onResultsFormClearBtnClick)

    if (resultsForm) {
        resultsForm.addEventListener('change', onResultsFormChange)

        resultsForm.addEventListener('submit', onResultsFormSubmit)
    }

    window
        .addEventListener('load', () => {
            const href = decodeURI(window.location.href)
                .replace(/(\[\d+\]=)/g, '[]=')
                .replace(/(\?|&)doing_wp_cron=.+$/, '')

            if (!('history' in window)
                    || window.location.href === href)
                return

            window.history.replaceState(null, '', href)
        })

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                searchCheckboxes
                    .forEach((input) =>
                        input.removeEventListener('click',
                            onSearchCheckboxClick))

                modalFiltersClearBtns
                    .forEach((btn) =>
                        btn.removeEventListener('click',
                            onClearModalFiltersBtnClick))

                if (modalFilters)
                    modalFilters
                        .removeEventListener('show.bs.modal',
                            onShowModalFilters)

                if (searchForm)
                    searchForm.removeEventListener('submit',
                        onSearchFormSubmit)

                if (resultsList)
                    resultsList.removeEventListener(
                        'end.loading-more.query-pagination', onResultsLoaded)

                if (resultsFormCtasClearBtn)
                    resultsFormCtasClearBtn.addEventListener(
                        'click', onResultsFormClearBtnClick)

                if (resultsForm) {
                    resultsForm.removeEventListener('change',
                        onResultsFormChange)

                    resultsForm.removeEventListener('submit',
                        onResultsFormSubmit)
                }
            })

    // loaded
    onResultsLoaded()

    console.log(el)

    console.groupEnd()

    return el
}
