export const selector = '.resources'

export default (
    el = null
) => {
    if (!el)
        return el

    console.groupCollapsed('Resources')

    const form = el.querySelector('form')

    if (!form)
        return el

    const submitBtn = form.querySelector('button[type=submit]')

    const getCheckedFiles = () => Array
        .from(form.querySelectorAll('[name="files[]"]:checked'))

    const onFormSubmit = (e) => !getCheckedFiles().length
        && e.preventDefault()

    const onFormChange = (e) => {
        if (!submitBtn)
            return

        if ((!getCheckedFiles().length
                    && !submitBtn.disabled)
                || (getCheckedFiles().length
                    && submitBtn.disabled))
            submitBtn.disabled = !submitBtn.disabled
    }

    form.addEventListener('submit', onFormSubmit)

    form.addEventListener('change', onFormChange)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                form.removeEventListener('submit', onFormSubmit)

                form.removeEventListener('change', onFormChange)
            })

    console.log(form)

    console.groupEnd()

    return el
}
